import {ICartItem} from "../../interfaces/cart-item.interface";
import {CartCustomerType, CustomerInterface,} from "../../interfaces/customer.interface";
import {AxiosError} from "axios";
import LogRocket from "logrocket";
import {CartActionType} from "../../interfaces/cart-action-type.enum";
import {AppState} from "../reducers";
import {CartStep} from "../../interfaces/cart-step";
import {poll} from "../../helpers/poll.helper";
import CartService from "../../services/cart.service";
import {CartDto} from "../../interfaces/cart-dto.interface";
import {DeliveryGroup, DeliveryMethodInterface,} from "../../interfaces/delivery-method.interface";
import {Dispatch} from "redux";
import {CartReducerActionInterface} from "../reducers/cart.reducer";
import {CartCheckoutInterface} from "../../interfaces/cart-checkout.interface";
import {StorageKeys} from "../../interfaces/storage-keys.enum";
import {jwtDecode} from "jwt-decode";
import {ErrorCodes} from "../../interfaces/error-codes.enum";
import {translationHelper} from "@eventiofi/eventio-commons-ui";
import config from "../../../webservice-config.json";

/**
 * Tarkistaa ostoskorin erääntymisen localStoragen eventio_cart_token valuesta
 * ja nollaa tarvittaessa session- sekä localStoragen sekä päivittää lopuksi sivun
 */
const checkExpiryStamp = () => {
    const token = localStorage.getItem(StorageKeys.EVENTIO_CART_TOKEN);

    if (!token) {
        return;
    }

    // Jos käyttäjä on striimisivulla, skipataan
    if (window.location.pathname.startsWith("/stream")) {
        return;
    }

    const decoded = jwtDecode(token);

    if (!decoded.exp) {
        console.error("Token does not have expiry stamp");
        return;
    }

    // Jos token on erääntynyt, tyhjätään kaikki local- sekä sessionStoragesta ja päivitetään sivu
    if (Date.now().valueOf() / 1000 > decoded.exp) {
        console.log("Cart expired, clearing...");
        LogRocket.track("Cart expired");
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
    }
};

/**
 * Lataa ostoskorin API:sta, jos localSotrageen on tallennettu ostoskori. Tarkistaa myös ostoskorin erääntymisen {@link checkExpiryStamp}
 * Uudelleenohjaa myös /cart/cancelled -sivulle, jos asiakas palautuu maksamisesta.
 */
export const loadCart = () => async (
    dispatch: Dispatch<CartReducerActionInterface>
) => {
    checkExpiryStamp();
    setInterval(checkExpiryStamp, 1000 * 60);

    const cartId = localStorage.getItem(StorageKeys.EVENTIO_CART_ID);
    const cartToken = localStorage.getItem(StorageKeys.EVENTIO_CART_TOKEN);

    // Jos localStoragesta löytyy ostoskori, ladataan sen tietojen perusteella ostoskori. Jos koria ei löydy localStoragesta TAI API:sta,  luodaan uusi ostoskori
    if (cartId && cartToken) {
        try {
            // Haetaan ostoskori API:sta
            const cart = await CartService.getCartById(cartId);

            if (cart) {
                dispatch({
                    type: CartActionType.APPLY_CART,
                    cart,
                });
                LogRocket.track("Existing cart found and applied");
            }
        } catch (e) {
            const error = e as AxiosError;
            if (!error.response) {
                LogRocket.captureException(error);
                dispatch({
                    type: CartActionType.CART_ERROR,
                    message: ErrorCodes.CART_LOADING_FAILED,
                });
                return;
            }
            LogRocket.captureException(error);
        }
    } else {
        LogRocket.track("Existing cart not found from localStorage");
    }
    if (localStorage.getItem(StorageKeys.EVENTIO_PAYMENT_PENDING)) {
        localStorage.removeItem(StorageKeys.EVENTIO_PAYMENT_PENDING);
        if (!window.location.pathname.includes("orders/pw") && !window.location.pathname.includes("thank-you")) {
            setCartStep(CartStep.CUSTOMER_DETAILS);
            window.location.href =
                process.env.GATSBY_WEBSITE_URL + "/cart/cancelled";
        }
    }
};

const createCart = async (dispatch: Dispatch<CartReducerActionInterface>) => {
    try {
        console.log("Action createCart")
        const cart = await CartService.createCart();
        LogRocket.track("Created a new cart");
        dispatch({
            type: CartActionType.APPLY_CART,
            cart,
        });
    } catch (e) {
        const error = e as AxiosError;
        LogRocket.captureException(error);
        dispatch({
            type: CartActionType.CART_ERROR,
            message: ErrorCodes.CART_CREATION_FAILED,
        });
    }
};

// TODO: Service
/**
 * Tyhjentää ostoskorin
 *
 * - poistaa tuotteet
 * - poistaa varaukset
 */
export const clearCart = () => (
    dispatch: Dispatch<CartReducerActionInterface>
) => {
    return dispatch({type: CartActionType.CLEAR_CART});
};

/**
 * Tyhjentää ostoskorin virheviestit
 */
export const clearCartError = () => (
    dispatch: Dispatch<CartReducerActionInterface>
) => dispatch({type: CartActionType.CLEAR_CART_ERROR});

/**
 * Tyhjentää checkoutin virheviestit
 */
export const clearCheckoutError = () => (
    dispatch: Dispatch<CartReducerActionInterface>
) => dispatch({type: CartActionType.CLEAR_CHECKOUT_ERROR});

/**
 * Lisää tai päivitä tuote ostoskoriin
 * @param item lisättävä/päivitettävä tuote
 */
export const addItemToCart = (item: ICartItem) => async (
    dispatch: Dispatch<CartReducerActionInterface>,
    getState: () => AppState
) => {
    try {
        let cart: CartDto | null;

        // Tarkistetaan, löytyykö tuote jo ostoskorista
        const cart_item = getState().cart.items.find(
            (cart_item) => cart_item.id === item.id
        );

        // Löytyy -> Päivitetään, Ei löydy -> Lisätään
        if (cart_item) {
            cart = await CartService.updateItem(item);
        } else {
            cart = await CartService.addItem(item);
        }

        // Päivitetään ostoskori
        dispatch({
            type: CartActionType.APPLY_CART,
            cart,
        });


        if (window.fbq) {
            window.fbq('track', 'AddToCart', {
                content_name: translationHelper(item.product.title, config.webservice.default_language),
                content_category: 'Products',
                content_ids: [item.product.id],
                contents: [{
                    id: item.id,
                    quantity: item.amount
                }],
                content_type: 'product',
                value: item.price,
                currency: 'EUR'
            });
        }
        LogRocket.track("Added item to cart");
    } catch (error) {
    }
};

export const updateItemToCart = (item: ICartItem) => async (
    dispatch: Dispatch<CartReducerActionInterface>,
) => {
    try {


        let cart: CartDto | null;

        cart = await CartService.updateItem(item);

        // Päivitetään ostoskori
        dispatch({
            type: CartActionType.APPLY_CART,
            cart,
        });

        if (window.fbq) {
            window.fbq('track', 'AddToCart', {
                content_name: translationHelper(item.product.title, config.webservice.default_language),
                content_category: 'Products',
                content_ids: [item.product.id],
                contents: [{
                    id: item.id,
                    quantity: item.amount
                }],
                content_type: 'product',
                value: item.price,
                currency: 'EUR'
            });
        }
        LogRocket.track("Added item to cart");
    } catch (error) {
    }
};

export const addItemsToCart = (items: ICartItem[]) => async (dispatch: Dispatch<CartReducerActionInterface>, getState: () => AppState) => {
    try {
        let cart: CartDto | null;

        // Tarkistetaan, löytyykö tuote jo ostoskorista

        const addableItems: ICartItem[] = [];

        for (const item of items) {
            // Löytyy -> Päivitetään, Ei löydy -> Lisätään
            const cart_item = getState().cart.items.find(
                (cart_item) => cart_item.id === item.id
            );

            if (cart_item) {
                cart = await CartService.updateItem(item);
            } else {
                addableItems.push(item);
            }
        }

        cart = await CartService.addItems(addableItems)

        // Päivitetään ostoskori
        dispatch({
            type: CartActionType.APPLY_CART,
            cart,
        });

        LogRocket.track("Added multiple items to cart");
    } catch (error) {
    }
}

/**
 * Poistaa tuotteen ostoskorista
 * @param item_id poistettavan tuotteen id
 */
export const removeFromCart = (item_id: string) => async (
    dispatch: Dispatch<CartReducerActionInterface>
) => {
    try {
        // Poistetaan tuote ostoskorista
        const cart = await CartService.removeItem(item_id);

        // Päivitetään ostoskori
        dispatch({
            type: CartActionType.APPLY_CART,
            cart,
        });

        LogRocket.track("Removed item from cart");
    } catch (error) {
    }
};

/**
 * Lisää koodin ostoskoriin
 * @param code - lisättävä koodi
 * @param redirect_url - mahdollinen uudelleenohjausosoite
 */
export const applyCode = (code: string, redirect_url?: string) => async (
    dispatch: Dispatch<CartReducerActionInterface>
) => {
    try {
        const cart = await CartService.applyCode(code);

        dispatch({
            type: CartActionType.APPLY_CART,
            cart: cart,
        });

        LogRocket.track("Applied campaign code to cart");
        if (redirect_url) {
            window.location.href = redirect_url;
        }
    } catch (error) {
    }
};

/**
 * Poista koodi ostoskorista
 * @param id poistettavan koodin id
 */
export const removeCode = (id: string) => async (
    dispatch: Dispatch<CartReducerActionInterface>
) => {
    try {
        const cart = await CartService.removeCode(id);
        dispatch({
            type: CartActionType.APPLY_CART,
            cart,
        });
    } catch (error) {
    }
};

/**
 * Lisää asiakkaan ostoskoriin
 * @param customer lisättävä asiakas
 * @param customer_type lisättän asiakkaan {@link CartCustomerType}
 */
export const addCustomerToCart = (
    customer: CustomerInterface,
    customer_type: CartCustomerType
) => async (
    dispatch: Dispatch<CartReducerActionInterface>,
    getState: () => AppState
) => {
    // Tallennetaan LogRocketin logeihin asiakastiedot
    if (customer_type === CartCustomerType.MAIN) {
        LogRocket.identify(getState().cart.uuid, {
            name: customer.first_name
                ? customer.first_name + " " + customer.last_name
                : "",
            first_name: customer.first_name || "",
            last_name: customer.last_name || "",
            email: customer.email || "",
            phone: customer.phone || "",
            company_name: customer.company_name || "",
        });
    }

    try {
        // Lisätään asiakas
        const cart = await CartService.addCustomer(customer, customer_type);

        LogRocket.track(`${customer_type}Customer added to cart`);

        // TODO: Onko seuraavan stepin antaminen ok?
        dispatch({
            type: CartActionType.APPLY_CART,
            cart: {...cart, step: CartStep.DELIVERY_DETAILS},
        });
    } catch (error) {
    }
};

/**
 * Lisää asiakkaan antamat kommentit / lisätiedot tilaukselle
 * @param comment lisättävä tieto
 */
export const addCommentsByCustomer = (comment: string) => async (
    dispatch: Dispatch<CartReducerActionInterface>
) => {
    try {
        const cart = await CartService.addCommentsByCustomer(comment);
        dispatch({
            type: CartActionType.APPLY_CART,
            cart,
        });
    } catch (error) {
    }
};

/**
 * Hae saatavilla olevat toimitustavat
 */
export const getAvailableDeliveryMethods = () => async (
    dispatch: Dispatch<CartReducerActionInterface>
) => {
    try {
        const delivery_methods = await CartService.getDeliveryMethods();

        dispatch({
            type: CartActionType.ADD_AVAILABLE_DELIVERY_METHODS,
            available_delivery_methods: delivery_methods,
        });
    } catch (error) {
    }
};

/**
 * Lisää toimitustavan valitulle {@link DeliveryGroup}ille
 * @param delivery_method lisättävä toimitustapa
 * @param delivery_group kohdetoimitusryhmä
 */
export const addDeliveryMethodToCart = (
    delivery_method: DeliveryMethodInterface,
    delivery_group: DeliveryGroup
) => async (dispatch: Dispatch<CartReducerActionInterface>) => {
    try {
        // Lisätään toimitustapa
        const cart = await CartService.addDeliveryMethod(
            delivery_method,
            delivery_group
        );

        LogRocket.track("Added delivery method to cart");

        dispatch({
            type: CartActionType.APPLY_CART,
            cart,
        });
    } catch (error) {
    }
};

/**
 * Siirtää ostoskorin seuraavaan vaiheeseen
 */
export const nextCartStep = () => (
    dispatch: Dispatch<CartReducerActionInterface>,
    getState: () => AppState
) =>
    dispatch({
        type: CartActionType.CART_STEP,
        step: (getState().cart.step || 0) + 1,
    });

/**
 * Siirtää ostoskorin edelliseen vaiheeseen
 */
export const prevCartStep = () => (
    dispatch: Dispatch<CartReducerActionInterface>,
    getState: () => AppState
) =>
    dispatch({
        type: CartActionType.CART_STEP,
        step: (getState().cart.step || 1) - 1,
    });

/**
 * Asettaa ostoskorin tiettyyn vaiheeseen
 * @param cart_step kohdevaihe
 */
export const setCartStep = (cart_step: CartStep) => (
    dispatch: Dispatch<CartReducerActionInterface>
) => {
    dispatch({
        type: CartActionType.CART_STEP,
        step: cart_step,
    });
};

// TODO: Service
/**
 * Resetoi ostoskorin
 */
export const resetCart = () => async (
    dispatch: Dispatch<CartReducerActionInterface>
) => {
    LogRocket.track("Reset cart");

    localStorage.removeItem(StorageKeys.EVENTIO_CART_ID);
    localStorage.removeItem(StorageKeys.EVENTIO_CART_TOKEN);

    dispatch({
        type: CartActionType.RESET_CART,
    });

    await createCart(dispatch);
};

/**
 * Luo checkoutin, jonka jälkeen alkaa pollaamaan tilauksen valmistumista sekä mahdollisesti uudelleenohjaa maksusivulle.
 */
export const createCheckout = () => async (
    dispatch: Dispatch<CartReducerActionInterface>,
) => {
    try {
        const checkout = await CartService.createCheckout();

        LogRocket.track("Created checkout");

        if(window.fbq) {
            let itemAmount = 0;
            const items = checkout.cart_checkout.cart.cart_items;
            if(items) {
                itemAmount = items.reduce((total, item) => total + item.amount ,0)
            }

            window.fbq('track', 'InitiateCheckout',  {
                content_category: 'Cart',
                content_ids: items?.map(item => item.id) || [],
                content_type: 'cart',
                num_items: itemAmount,
                value: checkout.cart_checkout.cart.total,
                currency: 'EUR'
            });
        }

        dispatch({
            type: CartActionType.CHECKOUT_CREATED,
            checkout: checkout.cart_checkout,
        });

        try {
            const response = await poll<CartCheckoutInterface>(
                () =>
                    CartService.getCartClient().get(
                        `${process.env.GATSBY_WS_API_URL}${checkout._links.status.href}`,
                    ),
                30000,
                1000
            );

            localStorage.setItem(StorageKeys.EVENTIO_PAYMENT_PENDING, "true");
            localStorage.setItem(StorageKeys.EVENTIO_CHECKOUT_ID, response.cart_checkout.uuid);

            // TODO: Tarkista näiden rivien tarpeellisuus
            localStorage.setItem(
                "last_checkout_id",
                response.cart_checkout.uuid
            );

            // Uudelleenohjataan, jos checkoutissa on forward_to_url-kenttä
            if (
                response.cart_checkout.next_step &&
                response.cart_checkout.next_step.forward_to_url
            ) {
                LogRocket.track("Redirecting to payment");
                window.location = response.cart_checkout.next_step.forward_to_url as any;
            }
        } catch (e) {
            const error = e as AxiosError;
            LogRocket.track("Error while polling checkout");
            LogRocket.captureException(error);
            dispatch({
                type: CartActionType.CHECKOUT_ERROR,
                message: error.message,
            });
        }
    } catch (e) {
        const error = e as AxiosError;
        LogRocket.captureException(error);
        dispatch({
            type: CartActionType.CHECKOUT_ERROR,
            message: "Tuntematon virhe tapahtui tilaustasi luodessa.",
        });
    } finally {
        dispatch({
            type: CartActionType.SET_CHECKOUT_LOADING,
            value: false
        })
    }
};

/**
 * Poistaa checkoutin
 */
export const deleteCheckout = () => async (
    dispatch: Dispatch<CartReducerActionInterface>,
) => {
    if (typeof window !== `undefined`) {
        LogRocket.track("Deleting checkout");
        dispatch({
            type: CartActionType.CART_STEP,
            step: CartStep.CUSTOMER_DETAILS,
        });

        const checkoutId = localStorage.getItem(StorageKeys.EVENTIO_CHECKOUT_ID);

        if (!checkoutId) return;

        const cartId = localStorage.getItem(StorageKeys.EVENTIO_CART_ID)

        try {

            await CartService.getCartClient().delete(`/carts/${cartId}/checkouts/${checkoutId}.json`);
            LogRocket.track("Checkout deleted");
        } catch (e) {
            const error = e as AxiosError;
            console.log(error);
            LogRocket.captureException(error);
        }

        localStorage.removeItem(StorageKeys.EVENTIO_CHECKOUT_ID);
    }
};
