import { Service } from "./service.interface";
import { ICartItem } from "../interfaces/cart-item.interface";
import { DeliveryGroup, DeliveryMethodInterface } from "../interfaces/delivery-method.interface";
import {
    MerchandiseProductTypes,
    ProductInterface,
    ProductType,
    TicketProductTypes,
} from "../interfaces/product.interface";
import { CartCustomerType, CustomerInterface } from "../interfaces/customer.interface";

import axios, { AxiosError, AxiosInstance } from "axios";
import LogRocket from "logrocket";
import { Customer } from "../classes/customer.class";
import { validate } from "class-validator";
import { AsYouType } from "libphonenumber-js";
import { CartInterface } from "../interfaces/cart.interface";
import { poll } from "../helpers/poll.helper";
import { CartDto } from "../interfaces/cart-dto.interface";
import { CartDeliveryMethodInterface } from "../interfaces/cart-delivery-method.interface";
import { CartStep } from "../interfaces/cart-step";
import { store } from "../store/store";
import { CartCheckoutInterface } from "../interfaces/cart-checkout.interface";
import { StorageKeys } from "../interfaces/storage-keys.enum";
import { SeatReservationInterface } from "../interfaces/seat-reservation.interface";
import { ICreateCartItem } from "../interfaces/cart/create-cart-item.interface";
import { CartActionType } from "../interfaces/cart-action-type.enum";
import { ErrorCodes } from "../interfaces/error-codes.enum";
import { IEventioHttpException } from "../interfaces/eventio-http-exception.interface";
import { addItemsToCart } from "../store/actions/cart.action";
import { CartReducerActionInterface } from "../store/reducers/cart.reducer";
import i18n from "../i18n";
import { RequiresCartExistence } from "./decorators/requires-cart-existence.decorator";

/**
 * CartService hoitaa rajapintakyselyt sekä toistuvat toiminnot liittyen ostoskoriin.
 */
class CartService implements Service {
    private readonly V2URL = `${process.env.GATSBY_WS_API_URL}/v2/${process.env.GATSBY_WS_ID}`;
    private readonly client!: AxiosInstance;

    private cartId!: string;
    private cartToken!: string;

    constructor() {
        if (typeof window === "undefined") return;

        this.cartId = localStorage.getItem(StorageKeys.EVENTIO_CART_ID) || "";
        this.cartToken = localStorage.getItem(StorageKeys.EVENTIO_CART_TOKEN) || "";

        this.client = axios.create({
            baseURL: this.V2URL,
            headers: {
                "x-eventio-cart-token": this.cartToken,
            },
        });

        // Error handler suoraan tähän
        this.client.interceptors.response.use((res) => res, (error) => {
            LogRocket.captureException(error);

            if (!error.response || (error.response && (typeof error.response.data.error === "string" || error.response.data.error instanceof String))) {
                store.dispatch({
                    type: CartActionType.CART_ERROR,
                    message: ErrorCodes.UNSPECIFIED_EXCEPTION,
                });
                throw error;
            }

            const eventioHttpError = error.response.data as IEventioHttpException;
            store.dispatch({
                type: CartActionType.CART_ERROR,
                message: eventioHttpError.error?.code,
                exception_parameters: eventioHttpError.error?.exception_parameters,
            });

            throw error;
        });
    }

    public getCartClient(): AxiosInstance {
        return this.client;
    }

    /**
     * Pikailmoittautuminen
     * @param customer asiakas
     * @param item tuote
     */
    public async createQuickCheckout(
        customer: CustomerInterface,
        item: ICartItem,
    ) {
        try {
            // Lisätään ostoskoriin tuote sekä asiakas
            await this.addItem(item);
            await this.addCustomer(customer, CartCustomerType.MAIN);

            // Haetaan saatavilla olevat toimitustavat
            const delivery_methods = await this.getDeliveryMethods();
            await this.addDeliveryMethod(
                delivery_methods[0],
                DeliveryGroup.TICKETS,
            );

            // Luodaan checkout
            const createCheckoutResponse = await this.createCheckout();

            // Checkout pollaukset tms
            try {
                const response = await poll<CartCheckoutInterface>(
                    () =>
                        this.client.get(
                            `${process.env.GATSBY_WS_API_URL}${createCheckoutResponse._links.status.href}`,
                        ),
                    30000,
                    1000,
                );
                LogRocket.track("Redirecting to payment");
                localStorage.setItem(StorageKeys.EVENTIO_CHECKOUT_ID, response.cart_checkout.uuid);
                localStorage.setItem(StorageKeys.EVENTIO_PAYMENT_PENDING, "true");
                localStorage.setItem("current_url", window.location.href);
                if (response.cart_checkout.next_step && response.cart_checkout.next_step.forward_to_url) {
                    window.location = <any>response.cart_checkout.next_step?.forward_to_url;
                }
            } catch (error) {
                LogRocket.track("Error while polling checkout");
                alert(
                    "Virhe tapahtui ilmoittautumista tehdessä. Ole hyvä ja kokeile uudelleen.",
                );
            }
        } catch (error) {
            alert(
                "Virhe tapahtui ilmoittautumista tehdessä. Ole hyvä ja kokeile uudelleen.",
            );
        }
    }

    /**
     * Pikatilaus (livesivu)
     *
     * @param customer asiakas
     * @param item tuote
     */
    public async createQuickOrder(
        customer: CustomerInterface,
        item: ICartItem,
    ) {
        await this.addItem(item);
        if (Object.keys(customer).length > 0) {
            await this.addCustomer(customer, CartCustomerType.MAIN);
        }

        const createCheckoutResponse = await this.createCheckout();

        try {
            const response = await poll<CartCheckoutInterface>(
                () =>
                    this.client.get(
                        `${process.env.GATSBY_WS_API_URL}${createCheckoutResponse._links.status.href}`,
                    ),
                30000,
                1000,
            );

            LogRocket.track("Redirecting to payment");
            localStorage.setItem(StorageKeys.EVENTIO_CHECKOUT_ID, response.cart_checkout.uuid);
            localStorage.setItem(StorageKeys.EVENTIO_PAYMENT_PENDING, "true");
            localStorage.setItem("current_url", window.location.href);
            if (response.cart_checkout.next_step && response.cart_checkout.next_step.forward_to_url) {
                window.location = <any>response.cart_checkout.next_step.forward_to_url;
            }
        } catch (e) {
            const error = e as AxiosError;
            LogRocket.captureException(error);
            console.log(error);
        }
    }

    /**
     * Validoi asiakastiedot
     * @param details asiakastiedot
     * @param customer_type asiakkaan tyyppi, oletuksena {@link CartCustomerType.MAIN}
     * @returns {Promise<{[key: string]: string}>} listan mahdollisista virheistä
     */
    public async validateCustomerDetails(
        details: {
            first_name?: string;
            last_name?: string;
            address_line1?: string;
            address_line2?: string;
            postal_code?: string;
            city?: string;
            country?: string;
            email?: string;
            phone?: string;
            company_name?: string;
            marketing_permission?: boolean;
        },
        customer_type: CartCustomerType = CartCustomerType.MAIN,
    ): Promise<{ [key: string]: string }> {
        const collectedErrors: any = {};
        const customer = Object.assign(new Customer(), {
            first_name: details.first_name,
            last_name: details.last_name,
            address_line1: details.address_line1,
            address_line2: details.address_line2,
            postal_code: details.postal_code,
            city: details.city,
            country: details.country,
            email: details.email ? details.email : undefined,
            phone: details.phone ? details.phone : undefined,
            company_name: details.company_name,
            marketing_permission: details.marketing_permission,
        });

        return new Promise((resolve, reject) => {
            validate(customer).then((validationErrors) => {
                validationErrors.forEach((error) => {
                    if (error.constraints) {
                        const constraint = Object.keys(error.constraints)[0];
                        collectedErrors[`${customer_type}${error.property}`] =
                            error.constraints[constraint];
                    }
                });
                resolve(collectedErrors);
            });
        });
    }

    /**
     * Muntaa puhelinnumeron oikeaan muotoon
     * @param phone muunnettava puhelinnumero
     * @return {string} muunnettu puhelinnumero
     */
    public modifyPhoneNumber(phone: string): string {
        let modifiedPhoneNumber = "";
        let typedPhone = new AsYouType("FI");
        typedPhone.input(phone);
        const phoneNumber = typedPhone.getNumber();
        if (phoneNumber && phoneNumber.isValid()) {
            modifiedPhoneNumber = phoneNumber.number as string;
        }

        return modifiedPhoneNumber;
    }

    /**
     * Rakentaa toimitustavat UI:lle sopivaksi {@link DeliveryGroup} perusteella
     * @param group käytettävä toimitusryhmä
     * @param products käytettävät tuotteet
     */
    public buildDeliveryMethods(
        group: DeliveryGroup,
        products: ProductInterface[],
    ): DeliveryMethodInterface[] {
        const methods = store.getState().cart.available_delivery_methods;
        const forbidden_delivery_methods: string[] = [];

        const productTypeGroup = this.getProductTypeGroupByDeliveryGroup(group);
        const items = this.getItemsByTypeGroup(
            store.getState().cart.items,
            productTypeGroup,
        );

        items.forEach((item) => {
            let product = products.find(
                (product) => product.id === item.product?.id,
            );
            if (!product) return null;
            if (product.forbidden_delivery_options) {
                product.forbidden_delivery_options.forEach((option) =>
                    forbidden_delivery_methods.push(option.id),
                );
            }
        });

        return methods
            .filter((method) => method.delivery_groups.includes(group))
            .filter((method) => !forbidden_delivery_methods.includes(method.id))
            .filter((method) => !method.hidden);
    }

    /**
     * Hakee oletustoimitustavan {@link DeliveryGroup}lle
     * @param group käytettävä toimitusryhmä
     * @param products käytettävät tuotteet
     * @return {DeliveryMethodInterface} oletustoimitustavan
     */
    public getDefaultDeliveryMethod(
        group: DeliveryGroup,
        products: ProductInterface[],
    ): DeliveryMethodInterface | null {
        const productTypeGroup = this.getProductTypeGroupByDeliveryGroup(group);
        const delivery_methods = this.buildDeliveryMethods(group, products);

        let defaultMethod: DeliveryMethodInterface | null = null;

        const productTypes: (ProductType | null)[] = this.getItemsByTypeGroup(
            store.getState().cart.items,
            productTypeGroup,
        ).map((item) => {
            let product = products.find(
                (product) => product.id === item.product?.id,
            );
            if (!product) return null;
            return product.type;
        });

        delivery_methods.forEach((delivery_method) => {
            if (!delivery_method.available_for_product_types) return;
            if (
                !delivery_method.available_for_product_types.some((type) =>
                    productTypes.includes(type),
                )
            )
                return;

            // Get first method that has "group" in delivery groups
            const method = delivery_methods.find((method) =>
                method.delivery_groups.includes(group),
            );
            // Set default method
            if (method) {
                defaultMethod = method;
            }
        });

        return defaultMethod;
    }

    /**
     * Hakee tuotteet ostoskorista niiden toimitustaparyhmän mukaan
     * @param items filtteröitävät tuotteet
     * @param type_group käytettävä tuotetyyppiryhmä ({@link TicketProductTypes} tai {@link MerchandiseProductTypes})
     * @private
     */
    private getItemsByTypeGroup(
        items: ICartItem[],
        type_group: ProductType[],
    ): ICartItem[] {
        return items.filter((item) => type_group.includes(item.product.type));
    }

    /**
     * Hakee {@link ProductType}n {@link DeliveryGroup}in perusteella
     * @param group käytettävä toimitustaparyhmä
     * @private
     */
    public getProductTypeGroupByDeliveryGroup(
        group: DeliveryGroup,
    ): ProductType[] {
        switch (group) {
            case DeliveryGroup.MERCHANDISE:
                return MerchandiseProductTypes;
            case DeliveryGroup.TICKETS:
                return TicketProductTypes;
        }
    }

    /**
     * Luo uusi ostoskori
     * @return {Promise<CartDto>} luodun ostoskorin
     */
    public async createCart(): Promise<CartInterface | null> {
        const response = await axios.post<{ cart: CartDto }>(`${this.V2URL}/carts.json`);

        // Tallennetaan ostoskorin id sekä token
        localStorage.setItem(
            StorageKeys.EVENTIO_CART_ID,
            response.data.cart.uuid,
        );
        localStorage.setItem(
            StorageKeys.EVENTIO_CART_TOKEN,
            response.data.cart.token,
        );

        this.cartId = response.data.cart.uuid;
        this.cartToken = response.data.cart.token;

        console.info("Created a new cart", this.cartId);

        return this.buildCartUIJson(response.data.cart);
    }

    /**
     * Hakee ostoskorin rajapinnasta
     * @param id ostoskorin id
     * @return {Promise<CartInterface>} ostoskorin, jos sellainen löytyy
     */
    public async getCartById(id: string): Promise<CartInterface | null> {
        this.setLoading();
        const response = await this.client.get<{ cart: CartDto }>(
            `${this.V2URL}/carts/${id}.json`,
        );
        return this.buildCartUIJson(response.data.cart);
    }

    /**
     * Lisää asiakas ostoskoriin
     * @param customer lisättävä asiakas
     * @param customer_type asiakkaan {@link CartCustomerType}
     * @return {Promise<CartInterface>} päivitetyn ostoskorin
     */
    @RequiresCartExistence
    public async addCustomer(
        customer: CustomerInterface,
        customer_type: CartCustomerType,
    ): Promise<CartInterface> {
        this.setLoading();
        const response = await this.client.put<{ cart: CartDto }>(
            `${this.V2URL}/carts/${this.cartId}/${customer_type}customer.json`,
            {
                [`${customer_type}customer`]: await this.buildCustomer(customer),
            },
        );
        return this.buildCartUIJson(response.data.cart);
    }

    /**
     * Hae saatavilla olevat toimitustavat ostoskorille
     * @return {Promise<DeliveryMethodInterface[]>} saatavilla olevat toimitustavat
     */
    @RequiresCartExistence
    public async getDeliveryMethods(): Promise<DeliveryMethodInterface[]> {
        store.dispatch({ type: CartActionType.SET_DELIVERY_LOADING, value: true });
        const response = await this.client.get<{
            delivery_methods: DeliveryMethodInterface[];
        }>(`${this.V2URL}/carts/${this.cartId}/delivery_methods.json`);

        return response.data.delivery_methods;
    }

    /**
     * Lisää toimitustapa ostoskorille
     * @param delivery_method lisättävä toimitustapa, joka muunnetaan oikeaan muotoon
     * @param delivery_group toimitustavan {@link DeliveryGroup}
     * @return {Promise<CartInterface>} päivitetyn ostoskorin
     */
    @RequiresCartExistence
    public async addDeliveryMethod(
        delivery_method: DeliveryMethodInterface,
        delivery_group: DeliveryGroup,
    ): Promise<CartInterface> {
        this.setLoading();
        // Rakennetaan ostoskorille sopiva toimitustapa
        const converted_method: CartDeliveryMethodInterface = {
            delivery_method: {
                id: delivery_method.id,
            },
            delivery_group,
            price_token: delivery_method.product?.price_token,
        };

        const response = await this.client.post<{ cart: CartDto }>(
            `${this.V2URL}/carts/${this.cartId}/delivery_methods.json`,
            {
                delivery_method: converted_method,
            },
        );

        return this.buildCartUIJson(response.data.cart);
    }

    /**
     * Lisää tuote ostoskoriin
     * @param item lisättävä tuote
     * @return {Promise<CartInterface>} päivitetyn ostoskorin
     */
    @RequiresCartExistence
    public async addItem(item: ICartItem): Promise<CartInterface> {
        this.setCartItemLoading();
        const response = await this.client.post<{ cart: CartDto }>(
            `${this.V2URL}/carts/${this.cartId}/cart_items.json`,
            {
                cart_item: this.buildAPIItem(item),
            },
        );
        return this.buildCartUIJson(response.data.cart);
    }

    /**
     * Lisää useampi tuote ostoskoriin
     * @param items lisättävät tuotteet
     * @return {Promise<CartInterface>} päivitetyn ostoskorin
     */
    @RequiresCartExistence
    public async addItems(items: ICartItem[]): Promise<CartInterface> {
        this.setCartItemLoading();
        const response = await this.client.post<{ cart: CartDto }>(
            `${this.V2URL}/carts/${this.cartId}/cart_items.json`,
            {
                cart_items: items.map(item => this.buildAPIItem(item)),
            },
        );
        return this.buildCartUIJson(response.data.cart);
    }

    /**
     * Päivitä tuotetta ostoskorissa
     * @param item päivitettävä tuote
     * @return {Promise<CartInterface>} päivitetyn ostoskorin
     */
    @RequiresCartExistence
    public async updateItem(item: ICartItem): Promise<CartInterface> {
        this.setCartItemLoading();
        const response = await this.client.patch<{ cart: CartDto }>(
            `${this.V2URL}/carts/${this.cartId}/cart_items/${item.id}.json`,
            {
                cart_item: this.buildAPIItem(item),
            },
        );
        return this.buildCartUIJson(response.data.cart);
    }

    /**
     * Poista tuote ostoskorista
     * @param id poistettavan tuotteen id
     * @return {Promise<CartInterface>} päivitetyn ostoskorin
     */
    @RequiresCartExistence
    public async removeItem(id: string): Promise<CartInterface> {
        this.setCartItemLoading();
        const response = await this.client.delete<{ cart: CartDto }>(
            `${this.V2URL}/carts/${this.cartId}/cart_items/${id}.json`,
        );
        return this.buildCartUIJson(response.data.cart);
    }

    /**
     * Rakentaa API:lle sopivan cart itemin
     * @param item ostoskorituoterivi, josta rakennetaan
     * @return {ICreateCartItem} - API:lle sopiva objekti
     * @private
     */
    private buildAPIItem(item: ICartItem): ICreateCartItem {
        return {
            amount: item.amount,
            price: item.price || 0,
            product: {
                id: item.product.id,
            },
            seat_reservations: item.seat_reservations?.map(item => ({ id: item.id })),
            event_registration: item.event_registration,
            customer_remarks: item.customer_remarks,
        };
    }

    /**
     * Luo varaus tapahtumaan
     * @param event_id tapahtuman id
     * @param seat_count varattavien paikkojen määrä
     * @param seat_id paikan id
     * @param section_id katsomon id
     * @returns {Promise<SeatReservationInterface[]>} paikkavaraukset listassa
     */
    @RequiresCartExistence
    public async createSeatReservation(event_id: string, seat_count: number, seat_id?: string, section_id?: string): Promise<SeatReservationInterface[]> {
        const body: any = {
            event: {
                id: event_id,
            },
            seat_count: seat_count,
        };

        if (seat_id) {
            body["seat"] = { chart_id: seat_id };
        }

        if (section_id) {
            body["section"] = { id: section_id };
        }

        const response = await this.client.post<{ seat_reservations: SeatReservationInterface[] }>(`${this.V2URL}/carts/${this.cartId}/seat_reservations.json`, body);
        const reservations = response.data.seat_reservations;

        const items: ICartItem[] = [];

        for (const reservation of reservations) {
            const item: ICartItem = {
                id: "",
                product: reservation.available_products[0],
                // @ts-ignore
                seat_reservations: reservations,
                amount: 1,
                price: reservation.available_products[0].price,
            };
            items.push(item);
        }

        if (items.length > 0) {
            // @ts-ignore
            await store.dispatch<CartReducerActionInterface>(addItemsToCart(items));
        }

        return reservations;
    }

    /**
     * Peruuta varaus
     * @param id varauksen id
     */
    @RequiresCartExistence
    public async cancelReservation(id: number): Promise<void> {
        await this.client.delete(`${this.V2URL}/carts/${this.cartId}/seat_reservations/${id}.json`);
    }

    /**
     * Peruuta kaikki varaukset tapahtumaan
     * @param event_id tapahtuman id
     * @param section_id katsomon id
     */
    @RequiresCartExistence
    public async cancelAllReservations(event_id: string, section_id: string): Promise<void> {
        await this.client.delete(`${this.V2URL}/carts/${this.cartId}/seat_reservations.json?event_id=${event_id}&section_id=${section_id}`);
    }

    /**
     * Hakee annetusta tapahtumasta nykyisen ostoskorin paikkavaraukset
     * @param event_id tapahtuman id
     */
    public async getSeatReservations(event_id: string): Promise<SeatReservationInterface[]> {
        const response = await this.client.get<{ seat_reservations: SeatReservationInterface[] }>(`${this.V2URL}/carts/${this.cartId}/seat_reservations.json?event=${event_id}`);
        return response.data.seat_reservations;
    }

    /**
     * Lisää asiakkaan kommentit
     * @param comments lisättävä kommentti
     * @return {Promise<CartInterface>} päivitetyn ostoskorin
     */
    @RequiresCartExistence
    public async addCommentsByCustomer(
        comments: string,
    ): Promise<CartInterface> {
        const response = await this.client.put<{ cart: CartDto }>(
            `${this.V2URL}/carts/${this.cartId}/comments_by_customer.json`,
            {
                comments_by_customer: comments,
            },
        );
        return this.buildCartUIJson(response.data.cart);
    }

    /**
     * Lisää koodi ostoskoriin
     * @param code lisättävä koodi
     * @return {Promise<CartInterface>} päivitetyn ostoskorin
     */
    @RequiresCartExistence
    public async applyCode(code: string): Promise<CartInterface> {
        this.setLoading();
        const response = await this.client.post<{ cart: CartDto }>(
            `${this.V2URL}/carts/${this.cartId}/codes.json`,
            {
                code,
            },
        );
        return this.buildCartUIJson(response.data.cart);
    }

    /**
     * Poista koodi ostoskorista
     * @param code poistettava koodi
     * @return {Promise<CartInterface>} päivitetyn ostoskorin
     */
    @RequiresCartExistence
    public async removeCode(code: string): Promise<CartInterface> {
        this.setLoading();
        const response = await this.client.delete<{ cart: CartDto }>(
            `${this.V2URL}/carts/${this.cartId}/codes/${code}.json`,
        );
        return this.buildCartUIJson(response.data.cart);
    }

    /**
     * Luo uuden checkoutin
     * @return {Promise<CartCheckoutInterface>} luodun checkoutin
     */
    @RequiresCartExistence
    public async createCheckout(): Promise<CartCheckoutInterface> {
        store.dispatch({
            type: CartActionType.SET_CHECKOUT_LOADING,
            value: true,
        });

        const res = await this.client.post<CartCheckoutInterface>(
            `${process.env.GATSBY_WS_API_URL}/v2/${process.env.GATSBY_WS_ID}/carts/${this.cartId}/checkouts.json?lang=${i18n.language}`,
        );
        return res.data;
    }

    /**
     * Rakentaa API:lle lähetettävän asiakkaan
     * @param json UI:n puolella luodut asiakastiedot
     * @return {Customer} API-kelpoinen asiakas
     */
    public buildCustomer(json: CustomerInterface): Customer {
        const customer = new Customer();
        customer.first_name = json.first_name || undefined;
        customer.last_name = json.last_name || undefined;
        customer.email = json.email ? json.email.toLowerCase() : undefined;
        customer.phone = json.phone
            ? this.modifyPhoneNumber(json.phone)
            : undefined;
        customer.address_line1 = json.address_line1 || undefined;
        customer.address_line2 = json.address_line2 || undefined;
        customer.city = json.city || undefined;
        customer.postal_code = json.postal_code || undefined;
        customer.country = json.country || undefined;
        customer.company_name = json.company_name || undefined;
        customer.marketing_permission = json.marketing_permission || false;
        return customer;
    }

    /**
     * Rakentaa ostoskorin UI:lle sopivaksi
     * @param cart konvertoitava ostoskori
     * @return {CartInterface} UI-kelpoinen ostoskori
     */
    private async buildCartUIJson(cart: CartDto): Promise<CartInterface> {
        const products = store.getState().product.products;

        // Mapataan reduxista tuotteet
        for (const item of cart.cart_items || []) {
            let product = products.find(
                (product) => product.id === item.product.id,
            );
            if (product) {
                item.product = product;
                continue;
            }

            try {
                const response = await axios.get<{ product: ProductInterface }>(
                    `${process.env.GATSBY_WS_API_URL}/v1/${process.env.GATSBY_WS_ID}/products/${item.product.id}.json`,
                );
                item.product = response.data.product;
            } catch (error) {
                LogRocket.captureException(error as AxiosError);
            }
        }

        return {
            uuid: cart.uuid,
            items: cart.cart_items || [],
            customer: cart.customer || {},
            delivery_customer: cart.delivery_customer || {},
            comments_by_customer: cart.comments_by_customer,
            delivery_methods: cart.delivery_methods || [],
            codes_used: cart.codes || [],
            totalEur: cart.total || 0,
            token: cart.token || store.getState().cart.token,

            // TODO: API cart convert (?)
            created: true,
            available_delivery_methods:
                store.getState().cart.available_delivery_methods || [],
            step: store.getState().cart.step || CartStep.CUSTOMER_DETAILS,
            isLoading: false,
            isLoadingDeliveryMethods: false,
            isLoadingCheckout: false,
            isLoadingCartItem: false,
        };
    }

    private setLoading() {
        store.dispatch({ type: CartActionType.SET_LOADING, value: true });
    }

    private setCartItemLoading() {
        store.dispatch({ type: CartActionType.SET_CART_ITEM_LOADING, value: true });
    }
}

export default new CartService();
